import(/* webpackMode: "eager" */ "/var/www/html/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["toast","warning"] */ "/var/www/html/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/ac.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/amenities.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/arowdown24.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/arrow-down-orange.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/arrow-left.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/arrowDown.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/arrowUp.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/backLeftArrow.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/bbq.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/bell.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/beverage.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/beverages.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/beverageWhite.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/calender.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/call-orange.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/call.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/callBlue.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/cancel.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/Catering.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/chair.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/chairs.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/change-loca.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/clock.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/clock16.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/close.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/coupon.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/couponApplied.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/current-location.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/delivery-box.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/delivery-history.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/delivery-location.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/Delivery.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/down-arrow.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/facebook.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/filter.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/filterDesktop.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/food.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/gps.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/hamburgur.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/HappinessCard.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/home.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/ic_round-star.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/info.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/infoOutlet.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/insta.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/kid.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/lift.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/liftIcon.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/location-pin.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/location.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/locationBlue.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/locationPinBlack20.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/locationSearchIcon.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/lock.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/logout.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/mapOrange.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/Menu-Icon.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/menu.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/minus.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/minusCounter.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/music.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/nonveg.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/orderConfirm.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/outlet.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/package.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/parking.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/parkingBlue.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/parkingIcon.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/plus.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/plusCounter.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/razorpay.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/reschedule.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/Reservations.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/reshaduled.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/restCardBtn1.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/restCardBtn2.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/right-arrow-white.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/right-arrow.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/sadEmoji.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/search.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/searchBlack.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/share-orange.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/share.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/shareOrange.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/slider-right.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/sliderRightMob.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/Smiles-emoji.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/smily.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/sort.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/takeAway.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/tick-icon.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/tickmark.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/twitter.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/user.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/veg.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/vegLeaf.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/wheelChair.svg");
;
import(/* webpackMode: "eager" */ "/var/www/html/public/icons/youtube.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/app/home/components/Carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/app/home/components/HappinesCards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/app/home/components/OurRestorents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/app/home/components/OurServices.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/html/src/app/home/components/ReserveTableBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/app/home/components/RestaurantsNearYou.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/app/home/components/Services.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/app/home/components/WhatsOnBBQ.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/src/app/home/FixedBar.tsx");
